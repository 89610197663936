<template>
  <div>
    <Notify></Notify>
    <CardForm class="mt-6"> </CardForm>
  </div>
</template>
<script>
import CardForm from "../Components/CardForm";
import Input from "../Components/Input.vue";
import Notify from "../Components/Notify.vue";
import Stepper from "./Stepper.vue";
import { mapMutations, mapState } from "vuex";
export default {
  name: "Wizard",
  components: {
    CardForm,
    Input,
    Notify,
    Stepper,
  },
  data() {
    return {
      card: {
        title: this.$i18n.t("EditContractor"),
        loading: true,
        sub_title: this.$i18n.t("contractors.contractor info"),
        // stepper: true,
      },
      style_form: [
        {
          value: "",

          label: this.$i18n.t("Company Name"),
          placeholder: "Company Name",
          value_text: "company_name",
          req: true,
          rules: [(v) => !!v || this.$i18n.t("form.Item is required")],
        },
        {
          type: "number",
          value: "",
          label: this.$i18n.t("contractors.ibaw"),
          placeholder: "",
          value_text: "ibaw",
          req: true,
          rules: [(v) => !!v || this.$i18n.t("form.Item is required")],
        },
        {
          value: "",
          label: this.$i18n.t("First Name"),
          placeholder: "micel",
          value_text: "first_name",
          req: true,
          rules: [(v) => !!v || this.$i18n.t("form.Item is required")],
        },
        {
          value: "",
          label: this.$i18n.t("Last Name"),
          placeholder: "perior",
          value_text: "last_name",
          req: true,
          rules: [(v) => !!v || this.$i18n.t("form.Item is required")],
        },
        {
          type: "number",
          value: "",
          label: this.$i18n.t("contractors.mobile"),
          placeholder: "+963 978 789 699",
          value_text: "mobile",
          req: true,
          rules: [(v) => !!v || this.$i18n.t("form.Item is required")],
        },
        {
          value: "",
          label: this.$i18n.t("auth.Phone Number"),
          placeholder: "+963 978 789 699",
          value_text: "phone",
          req: true,
          rules: [(v) => !!v || this.$i18n.t("form.Item is required")],
        },
        {
          type: "number",
          value: "",
          label: this.$i18n.t("contractors.kvk"),
          placeholder: "",
          value_text: "kvk",
          req: true,
          rules: [(v) => !!v || this.$i18n.t("form.Item is required")],
        },
        {
          type: "number",
          value: "",
          label: this.$i18n.t("contractors.btw"),
          placeholder: "",
          value_text: "btw",
          req: true,
          rules: [(v) => !!v || this.$i18n.t("form.Item is required")],
        },

        {
          col: "12",
          type: "textarea",
          label: this.$i18n.t("address"),
          error: null,
          value: "",
          rules: [(v) => !!v || this.$i18n.t("news is required")],
          label: this.$i18n.t("address"),
          placeholder: "",
          value_text: "address",
        },
      ],
    };
  },

  computed: {
    ...mapState("form", ["formData"]),
  },
  methods: {
    ...mapMutations([
      "SET_COLLECTION",
      "SET_FUNCTION",
      "SET_CARD",
      "SET_ID",
      "SET_CARD_LOADING",
    ]),
    ...mapMutations("form", ["SET_FORM_STYLE", "SET_LOADING_STEPPER"]),
    set_data() {
      this.SET_COLLECTION("user");
      this.SET_FUNCTION("edit_contractor");
      this.SET_FORM_STYLE(this.style_form);
      this.SET_CARD(this.card);
      this.SET_ID(this.$route.params.id);
      this.SET_CARD_LOADING(false);
    },

    async get_data() {
      await this.$http.get("/contractors/edit/" + this.$route.params.id).then(
        (response) => {
          this.style_form = this.style_form.map((v) => {
            if (v.value_text == "first_name" || v.value_text == "last_name") {
              v.value = response.data.contractor.user[v.value_text];
            } else v.value = response.data.contractor[v.value_text];
            return v;
          });
        },
        (error) => {
          // console.log(error);
        }
      );
    },
  },
  mounted() {
    this.get_data();
    this.set_data();
    document.title = this.$i18n.t("EditContractor");
  },
};
</script>